<template>
  <div>
    <div class="Uppy"></div>
    <div class="UppyProgressBar"></div>
  </div>
</template>

<script>
import Uppy from '@uppy/core';
import FileInput from '@uppy/file-input';
import ProgressBar from '@uppy/progress-bar';
import Transloadit from '@uppy/transloadit';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import axios from '../axios';

export default {
  name: 'Uploader',
  computed: {
    basketId() {
      return this.$store.state.basketId;
    },
    customerId() {
      const customerRecord = this.$store.state.customer;
      if (customerRecord.id) {
        return customerRecord.id;
      }
      return 0;
    },
  },
  methods: {
    saveToServer(fileData) {
      const postData = {};
      postData.files = fileData;
      postData.basket = this.basketId;
      postData.designId = this.designId;
      postData.customerId = this.customerId;
      axios.post('/Files/addFiles.json', postData)
        .then((response) => {
          const images = response.data;
          this.$store.commit('addImages', images);
          this.$emit('closeUploader');
        });
    },
  },
  mounted() {
    const uppy = new Uppy({ debug: true, autoProceed: true });
    uppy.use(FileInput, {
      target: '.Uppy',
      pretty: true,
    });
    uppy.use(ProgressBar, {
      target: '.UppyProgressBar',
      hideAfterFinish: false,
    });
    uppy.use(Transloadit, {
      service: 'https://api2.transloadit.com',
      params: {
        auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
        template_id: '57cdea0e1c8044bf9be2c4eca5f23686',
      },
      waitForEncoding: true,
      waitForMetadata: false,
      importFromUploadURLs: false,
      alwaysRunAssembly: false,
      signature: null,
    });
    uppy.on('complete', (result) => {
      this.saveToServer(result);
    });
  },
};
</script>

<style lang="scss">
  .uppy-FileInput-container {
    margin-bottom: 0;
  }

  .uppy-FileInput-btn {
    font-size: 0;
    width: 100%;
    background-color: #FFF;
    border: none;
    border-radius: 24px;
    padding: 3px 10px;
    border: 2px solid #FFF;
    box-shadow: 5px 1px 5px #DDD;

    &::before {
      content: 'Upload files';
      font-size: 16px;
    }
  }

  .UppyProgressBar {
    position: relative;
    top: -3px;
    width: 95%;
    margin-left: 2.5%;
  }
</style>
